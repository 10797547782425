/* lato-100 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    src: url('/assets/fonts/lato/lato-v23-latin-100.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v23-latin-100.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-100italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: italic;
    font-weight: 100;
    src: url('/assets/fonts/lato/lato-v23-latin-100italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v23-latin-100italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/lato/lato-v23-latin-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v23-latin-300.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-300italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: italic;
    font-weight: 300;
    src: url('/assets/fonts/lato/lato-v23-latin-300italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v23-latin-300italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/lato/lato-v23-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v23-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/lato/lato-v23-latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v23-latin-italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/lato/lato-v23-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v23-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-700italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    src: url('/assets/fonts/lato/lato-v23-latin-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v23-latin-700italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-900 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url('/assets/fonts/lato/lato-v23-latin-900.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v23-latin-900.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-900italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: italic;
    font-weight: 900;
    src: url('/assets/fonts/lato/lato-v23-latin-900italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/assets/fonts/lato/lato-v23-latin-900italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
