// Responsive points

//Media query's don't work with css variables this is a limitation of the variables.
//So that's why there are less variables

@xxs: 0;
@xs: 419px;
@sm: 639px;
@md: 767px;
@lg: 1023px;
@xl: 1279px;
@xxl: 1535px;

:root {
    // Branding colors
    --primary: #ef7c00;
    --primary-dark: #41b6e6;

    --primary-light: #f8b472;
    --primary-light-dark: #f8b472;

    --primary-lighter: #80d8f6;
    --primary-lighter-dark: #80d8f6;

    --secondary: #3c1053;
    --secondary-dark: #3c1053;

    --secondary-light: #4e1f62;
    --secondary-light-dark: #4e1f62;

    --secondary-lighter: #582c83;
    --secondary-lighter-dark: #582c83;

    // Neutrals
    --neutral1: #efefe9;
    --neutral1-dark: #bebba3;

    --neutral2: #f2f2f2;
    --neutral2-dark: #585858;

    --neutral3: #838380;
    --neutral3-dark: #717171;

    --neutral4: #979d9f;
    --neutral4-dark: #909090;

    --neutral5: #4d4d4d;
    --neutral5-dark: #9e9e9e;

    --black: #191919;
    --black-dark: #fff;

    --white: #fff;
    --white-dark: #191919;

    // Background
    --background: var(--white);
    --background-dark: var(--white);

    --body-background: #ffffff;
    --body-background-dark: #2a2a2a;

    // Fonts
    --font: #272727;
    --font-dark: #fff;

    --light-font: #7d7d7d;
    --light-font-dark: #7d7d7d;

    --disabled-font: #bac3c8;
    --disabled-font-dark: #bac3c8;

    // Miscellaneous
    --border: #25313c;
    --border-dark: #d5d5d5;

    // State colors
    --error: #f6554a;
    --error-dark: #f6554a;
    --error-light: #fdeceb;
    --error-light-dark: #fdeceb;
    --success: #65bb6a;
    --success-dark: #65bb6a;
    --success-light: #edf7ee;
    --success-light-dark: #edf7ee;
    --alert: #fea228;
    --alert-dark: #fea228;
    --alert-light: #fff4e5;
    --alert-light-dark: #fff4e5;
    --notification: #41b6e6;
    --notification-dark: #41b6e6;
    --notification-light: #e9f4fd;
    --notification-light-dark: #e9f4fd;
}

// Status
@statusComplete: #9ed900;
@statusIncomplete: #c91800;
@statusPartialComplete: #e6c632;

// Borders
@defaultBorder: 1px solid var(--border);
@defaultBorderRadius: 5px;

// Navigation
@navColor: var(--white);
@navTopHeight: 80px;
@navSideWidth: 240px;

//footer
@footerBackgroundColor: var(--white);
@footerUnderbarBackground: var(--neutral3);
@footerUnderbarColor: var(--neutral5);
@footerSocialBackgroundHover: var(--font);
@footerSocialcolorHover: var(--white);
@footerFontColor: var(--font);
@footerBorderColor: var(--font);

// Shadow
@defaultShadow: 0px 0px 23px #0000000d;
@defaultBoxShadow: 0px 3px 6px #00000029;

// Animation
@defaultAnimation: 0.2s linear;

// Font
@defaultFont: 'Amaranth', sans-serif;

@fontSizeHeader: 32px;
@fontSizeLarge: 24px;
@fontSizeMedium: 18px;
@defaultFontSize: 14px;
@fontSizeSmall: 12px;
@fontSizeTiny: 9px;

@lineHeightHeader: 40px;
@lineHeightLarge: 32px;
@lineHeightMedium: 24px;
@defaultLineHeight: 20px;
@lineHeightSmall: 16px;

// Tables
@tableHeadBackground: #666666;
@tableHeadColor: #ffffff;
@tableRowHover: #fcefb5;
@tableRowBackgroundOdd: #ffffff;
@tableRowBackgroundEven: #d9d9d9;

// Miscellaneous
@headerHeight: 45px;
@tbBackgroundColor: var(--white);
@toggleBarUncheckedColor: var(--neutral2);
@toggleShadowColor: #00000029;
@toggleShadow: 0px 0px 3px @toggleShadowColor;
@tbDefaultWidth: 16px;

@tbDisabledColor: var(--neutral2);
@toggleBarDisabledColor: var(--neutral3);

@tbCheckedColor: var(--primary);
@toggleBarCheckedColor: var(--primary-light);

// Radiobutton
@radioButtonColor: var(--primary);
@radioButtonBackgroundColor: var(--background);
@radioButtonLabelColor: var(--font);
@radioButtonBorderColor: var(--border);
@radioButtonDefaultWidth: 16px;
@radioButtonFontSize: @defaultFontSize;

// disabled
@radioButtonDisabledColor: var(--neutral3);
@radioButtonDisabledLabelColor: #999999;

// checked
@radioButtonDefaultCheckWidth: 8px;
@radioButtonCheckedColor: var(--primary);

//default
@checkboxDefaultWidth: 20px;
@checkboxLabelColor: var(--black);
@checkboxColor: #3c1053;
@checkboxBackgroundColor: #ffffff;
@checkboxBorderColor: #a2a2a2;
@checkboxShadowColor: #f0f0f0;

// checked
@checkboxCheckedColor: var(--white);
@checkboxCheckedBackgroundColor: var(--primary);
@checkboxCheckedBorderColor: var(--primary);
@checkboxCheckedDisabledColor: var(--white);
@checkboxCheckedDisabledLabelColor: #afbec5;

// disabled
@checkboxDisabledLabelColor: var(--font);
@checkboxDisabledBackgroundColor: var(--neutral4);
@checkboxDisabledBorderColor: var(--neutral4);

// full color
@checkboxFullColorBackgroundColor: #607d8b;
@checkboxFullColorColor: #ffffff;
@checkboxFullColorDisabledColor: #f8f8f8;
@checkboxFullColorDisabledBackgroundColor: #afbec5;

// Input

// Default
@inputDefaultBackground: var(--white);
@inputDefaultBorder: @defaultBorder;
@inputDefaultColor: var(--neutral3);
@inputBorderColor: var(--border);
@inputHeight: 40px;
@inputDefaultBorderRadius: 2px;

// hover
@inputHoverBorder: 1px solid var(--neutral4);

// focus
@inputFocusBorder: @inputHoverBorder;
@inputFocusShadow: 0px 0px 6px #607d8b;

// validation
@inputValidationErrorColor: var(--error);

// disabled
@inputDisabledBackgroundColor: var(--neutral1);
@inputDisabledBorderColor: @inputBorderColor;
@buttonDisabledColor: var(--neutral1-dark);
@buttonDisabledBackgroundColor: var(--neutral1);

// read only

// Button Styles
@defaultButtonBorderRadius: 0px;
@defaultButtonHeight: 46px;

// default (dark)
@defaultButtonBackgroundColor: var(--primary);
@defaultButtonBorderColor: var(--primary);
@defaultButtonFontColor: var(--white);

// default (light)
@defaultButtonBackgroundColorLight: var(--neutral2);
@defaultButtonBorderColorLight: var(--neutral2);
@defaultButtonFontColorLight: var(--font);

// default (outline)
@defaultButtonBackgroundColorOutline: var(--white);
@defaultButtonBorderColorOutline: var(--black);
@defaultButtonFontColorOutline: var(--font);

// hover
@hoverButtonShadow: @defaultBoxShadow;

// hover/pressed (dark)
@hoverButtonBackgroundColor: var(--primary-light);
@hoverButtonBorderColor: var(--primary-light);
@hoverButtonFontColor: var(--white);
@hoverTextFontColor: #41b6e6;

// hover/pressed (light)
@hoverButtonBackgroundColorLight: @defaultButtonBackgroundColorLight;
@hoverButtonBorderColorLight: @defaultButtonBackgroundColorLight;
@hoverButtonFontColorLight: var(--font);

// hover/pressed (outline)
@hoverButtonBackgroundColorOutline: var(--primary) Dark;
@hoverButtonBorderColorOutline: var(--primary) Dark;
@hoverButtonFontColorOutline: var(--white);

// focussed
@focusButtonBorderColor: var(--secondary);

// disabled
@disabledButtonOpacity: 0.5;

// General button styles
@filledFontColor: #ffffff;
@hoverBackgroundColor: #ffffff;

// Login button style
@loginColor: #014478;
// Login end

// Dark button style
@darkColor: #1c394c;
// Dark end

// Red button style
@redColor: #ea3f58;
// Red end

// Side Menu
@sMenuBackgroundColor: #fff;
@hamburgerColor: #2eb277;
@sMenuShadowColor: #555555;
@sMenuHoverColor: #fafafa;

// Blur Menu
@blurBackground: rgba(255, 255, 255, 0.8);
@blurItem: rgba(0, 0, 0, 0.04);
@blurFilter: blur(8px);

// Rail Sizing
@railWidth: 240px;

// Cropper colors
@cropperButtonBackground: #e3e8e8;
@cropperButtonColor: #191919;
@cropperBorder: 1px solid #d3d8d8;

// drag drop colors
@dragBoxShadow: 0px 3px 23px #00000029;
@dragBorder: 6px solid #41b6e6;
@innerDragBorder: 1px solid #c8d1d1;

// native table colors
@tableBorderHeadColor: var(--neutral4);
@tableBorderBodyColor: var(--neutral2);
@tableIconHeadColor: #c8d1d1;
@tableIconBodyColor: var(--neutral4);

// Search box colors
@searchBorder: @defaultBorder;
@searchBackground: #fff;

// Cam colors
@camBackground: #e3e8e8;
@camBorder: 1px solid #c8d1d1;
@camColor: #979d9f;

// Redactor
@redactorBackground: #ffffff;
@redactorFg: #444950;

// Signature colors
@signatureBackground: #e3e8e8;
@signatureBorder: #c8d1d1;
