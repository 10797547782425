/* amaranth-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Amaranth';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/amaranth/amaranth-v18-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/assets/fonts/amaranth/amaranth-v18-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* amaranth-italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Amaranth';
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/amaranth/amaranth-v18-latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/assets/fonts/amaranth/amaranth-v18-latin-italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* amaranth-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Amaranth';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/amaranth/amaranth-v18-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/assets/fonts/amaranth/amaranth-v18-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* amaranth-700italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Amaranth';
    font-style: italic;
    font-weight: 700;
    src: url('/assets/fonts/amaranth/amaranth-v18-latin-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/assets/fonts/amaranth/amaranth-v18-latin-700italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
